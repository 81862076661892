import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    //
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/one/contract/searchPageSales', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    initPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/one/contract/initPage', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
